<template>
  <iframe
    id="mif"
    :src="src_url"
    frameborder="0"
    allow="xr-spatial-tracking"
  ></iframe>

  <v-chip
    color="primary"
    @click="play_stop_clicked"
    style="position: fixed; top: 80px !important; right: 30px !important"
  >
    <v-icon v-if="!play_pause">mdi-play</v-icon>
    <v-icon v-else>mdi-pause</v-icon>
  </v-chip>

  <v-chip
    color="primary"
    @click="overview_clicked"
    style="position: fixed; top: 80px !important; right: 80px !important"
  >
    <v-icon v-if="overview">mdi-package-variant-closed</v-icon>
    <v-icon v-else>mdi-printer-3d</v-icon>
  </v-chip>

  <!-- <v-chip
    color="primary"
    @click="solution_clicked"
    style="position: fixed; top: 80px !important; right: 130px !important"
  >
    <v-icon v-if="show_solution">mdi-checkbox-marked-circle-outline</v-icon>
    <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>
  </v-chip> -->

  <v-chip
    color="primary"
    variant="tonal"
    prepend-icon="mdi-cursor-default-click"
    style="position: fixed; top: 80px !important; right: 130px !important"
    @click="settings.toggle3d()"
  >
    Solutions: {{ solutions.length }}
  </v-chip>
</template>

<script setup>
import { onMounted, watch, ref } from "vue";
import { useSystemRequestStore } from "@/stores/system_request";
import { useSettingsStore } from "@/stores/settings";
import { storeToRefs } from "pinia";

// TODO: this caused a bug where input fields disappeared.
// I don't know why, though... or where this function was used
// Array.prototype.contains = function (element) {
//   return this.indexOf(element) > -1;
// };

const src_url = "ident/index.html";
const settings = useSettingsStore();
const system_request = useSystemRequestStore();
const { request, solutions } = storeToRefs(system_request);

const play_pause = ref(true);
const show_solution = ref(false);
const overview = ref(true);

watch(solutions, (s) => {
  updateConveyor();
});

onMounted(() => {
  window.onmessage = function (event) {
    if (event.data.event == "callout") {
      updateConveyor();
    }
    window.onmessage = null;
  };
});

function overview_clicked() {
  const mf = document.getElementById("mif");
  if (mf == null) return;
  overview.value = !overview.value;
  if (overview.value) {
    mf.contentWindow.postMessage({
      event: "camera",
      id: "BARCODE",
      value: true,
    });
    mf.contentWindow.postMessage({
      event: "camera",
      id: "OVERVIEW",
      value: false,
    });
  } else {
    mf.contentWindow.postMessage({
      event: "camera",
      id: "OVERVIEW",
      value: true,
    });
    mf.contentWindow.postMessage({
      event: "camera",
      id: "BARCODE",
      value: false,
    });
  }
}

function play_stop_clicked() {
  play_pause.value = !play_pause.value;
  updateConveyor();
}

function solution_clicked() {
  show_solution.value = !show_solution.value;
  const mf = document.getElementById("mif");
  if (mf == null) return;
  mf.contentWindow.postMessage({
    event: "object-state",
    id: "SOLUTION",
    enabled: show_solution.value,
  });
  updateConveyor();
}

function get_conveyor_type() {
  const translation_map = {
    belt: "belt",
    roller: "roller",
  };
  const t = request.value["conveyor_type"].toLowerCase();
  return translation_map[t] ?? "belt";
}

function get_object_guided() {
  const translation_map = {
    none: "unguided",
    left: "left",
    right: "right",
    center: "center",
  };
  const t = request.value["conveyor_object_alignment"].toLowerCase();
  return translation_map[t] ?? "unguided";
}

function get_barcode_type() {
  return request.value["identifier"].map((ident) => ident.toLowerCase());
}

function updateConveyor() {
  const mf = document.getElementById("mif");
  if (mf == null) return;

  mf.contentWindow.postMessage({
    event: "object-state",
    id: "OBJECT_SPAWNER",
    enabled: false,
  });
  mf.contentWindow.postMessage({
    event: "barcode-position",
    value: request.value["object_reading_sides"].map(
      (pos) => pos.split("_")[1]
    ),
  });
  mf.contentWindow.postMessage({
    event: "conveyor-type",
    value: get_conveyor_type(),
  });
  mf.contentWindow.postMessage({
    event: "conveyor-width",
    value: request.value["conveyor_width"] / 1000,
  });
  mf.contentWindow.postMessage({
    event: "conveyor-velocity",
    value: request.value["conveyor_speed"],
  });
  mf.contentWindow.postMessage({
    event: "object-width-max",
    value: request.value["object_width"][1] / 1000.0,
  });
  mf.contentWindow.postMessage({
    event: "object-width-min",
    value: request.value["object_width"][0] / 1000.0,
  });
  mf.contentWindow.postMessage({
    event: "object-height-max",
    value: request.value["object_height"][1] / 1000.0,
  });
  mf.contentWindow.postMessage({
    event: "object-height-min",
    value: request.value["object_height"][0] / 1000.0,
  });
  mf.contentWindow.postMessage({
    event: "object-length-max",
    value: request.value["object_length"][1] / 1000.0,
  });
  mf.contentWindow.postMessage({
    event: "object-length-min",
    value: request.value["object_length"][0] / 1000.0,
  });
  mf.contentWindow.postMessage({ event: "conveyor-height", value: 0.5 });
  mf.contentWindow.postMessage({ event: "object-interval", value: 0.8 });
  mf.contentWindow.postMessage({
    event: "object-guided",
    value: get_object_guided(),
  });
  mf.contentWindow.postMessage({
    event: "object-aligned",
    value: request.value["object_rotation"] === "aligned",
  });
  mf.contentWindow.postMessage({
    event: "barcode-marking-type",
    value: "printed",
  });
  // mf.contentWindow.postMessage({ event: 'object-state', id: 'SOLUTION', enabled: true });
  mf.contentWindow.postMessage({
    event: "object-state",
    id: "CONVEYOR_LIMIT",
    enabled: false,
  });
  mf.contentWindow.postMessage({
    event: "object-state",
    id: "OBJECT",
    enabled: !play_pause.value,
  });
  mf.contentWindow.postMessage({
    event: "barcode-type",
    value: get_barcode_type(),
  });
  mf.contentWindow.postMessage({ event: "barcode-color", r: 0, g: 0, b: 0 });
  mf.contentWindow.postMessage({
    event: "barcode-background-color",
    r: 254,
    g: 254,
    b: 254,
  });
  mf.contentWindow.postMessage({
    event: "object-state",
    id: "OBJECT_SPAWNER",
    enabled: play_pause.value,
  });
}
</script>

<style scoped>
iframe {
  width: 100%;
  height: calc(100vh - 124px);
  display: block;
}
</style>
