<template>
  <ProjectActionPrompt ref="project_prompt" />
  <v-card id="application" :max-height="height - 124">
    <v-toolbar flat density="compact">
      <v-toolbar-title> {{ $t("application_specification") }} </v-toolbar-title>

      <BaseToolbarMenu :items="toolbarMenuActions" />
    </v-toolbar>

    <v-card-title>
      <v-row align="center" justify="space-between">
        <v-tabs
          v-model="tab"
          fixed
          style="
            width: 90%;
            margin-left: -8px;
            margin-bottom: 10px;
            margin-top: 5px;
          "
        >
          <v-tab
            v-for="section in system_request.sections"
            v-bind="props"
            :key="section.label"
            v-show="checkVisibility(section)"
          >
            <!-- <v-icon size="x-large">
              <img :src="section.icon" style="min-width:30px;"></img>
            </v-icon> -->

            {{
              $te("system_request_" + section.label)
                ? $t("system_request_" + section.label)
                : section[settings.locale]
            }}
          </v-tab>
        </v-tabs>

        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="text"
              icon="mdi-menu-down"
            ></v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in system_request.sections"
              :key="item.label"
              :value="index"
              v-show="checkVisibility(item)"
              @click="tab = index"
            >
              <v-list-item-title>
                {{
                  $te("system_request_" + item.label)
                    ? $t("system_request_" + item.label)
                    : item[settings.locale]
                }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
    </v-card-title>

    <v-card-text id="applicationCardText" class="pt-4">
      <v-window v-model="tab">
        <v-window-item
          v-for="(section, index) in system_request.sections"
          :key="index"
        >
          <ApplicationFormSection :section="section" />
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</template>

<script setup>
import router from "@/router";
import { ref, computed, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { useSystemRequestStore } from "@/stores/system_request";
import { useI18n } from "vue-i18n";
import { useSettingsStore } from "@/stores/settings";
import ProjectActionPrompt from "./ProjectActionPrompt.vue";
import ApplicationFormSection from "./ApplicationFormSection.vue";
import BaseToolbarMenu from "./BaseToolbarMenu.vue";
import { useWindowSize } from "@vueuse/core";
import { useApiSchemaAdapter } from "@/composables/useApiSchemaAdapter";
import useReportGenerator from "@/composables/useReportGenerator";

const { height } = useWindowSize();
const { mapIfOld } = useApiSchemaAdapter();
const props = defineProps(["projectID", "params"]);
const settings = useSettingsStore();
const project_prompt = ref(null);
const tab = ref(0);
const { t, te } = useI18n();
const system_request = useSystemRequestStore();
const { request, solutions, project_active, project, selected } =
  storeToRefs(system_request);
const { downloadApplicationSpecCSV } = useReportGenerator(t, te);

const toolbarMenuActions = computed(() => {
  return [
    {
      title: t("system_request__save_project"),
      icon: "mdi-content-save-outline",
      action: saveProject,
      ctrl_action: () => {},
      show: () => true,
    },
    {
      title: t("system_request__save_as"),
      icon: "mdi-content-copy",
      action: saveProjectAs,
      ctrl_action: () => {},
      show: () => {
        return project_active.value;
      },
    },
    {
      title: t("system_request__rename_project"),
      icon: "mdi-rename",
      action: renameProject,
      ctrl_action: () => {},
      show: () => {
        return project_active.value;
      },
    },
    {
      title: t("system_request__create_link"),
      icon: "mdi-export-variant",
      action: () => system_request.share_project(false),
      ctrl_action: () => system_request.share_project(true),
      show: () => true,
    },
    {
      title: t("system_request__new_search"),
      icon: "mdi-archive-plus",
      action: newSearch,
      ctrl_action: () => {},
      show: () => true,
    },
    {
      title: t("system_request__export_project"),
      icon: "mdi-microsoft-excel",
      action: downloadApplicationSpecCSV,
      ctrl_action: () => {},
      show: () => true,
    },
  ];
});

function saveProject() {
  if (project_active.value) {
    system_request.update_project();
  } else {
    system_request
      .create_dialog(project_prompt, system_request.request)
      .then((res) => {
        router.push({
          name: "project",
          params: { projectID: res.id },
        });
      });
  }
}

function saveProjectAs() {
  system_request
    .copy_dialog(project_prompt, system_request.request)
    .then((res) => {
      router.push({ name: "project", params: { projectID: res.id } });
    });
}

function renameProject() {
  system_request
    .edit_dialog(project_prompt, system_request.project)
    .then((res) => {
      system_request.project = res;
    })
    .catch((err) => {
      if (err) {
        notification_center.push_notification(
          t("systems__project_deleted_failed"),
          err,
          NotificationType.Error
        );
      }
    });
}

function newSearch() {
  router.push("/");
  system_request.reset().then(() => system_request.get_solutions());
}

onMounted(() => {
  if (props.params != null) {
    system_request
      .reset()
      .then(() => {
        request.value = mapIfOld(JSON.parse(props.params));
      })
      .then(() => system_request.get_solutions());
  } else if (props.projectID) {
    system_request
      .set_project(props.projectID)
      .then((res) => {
        selected.value = solutions.value.find((e) => {
          return e.id == project.value.favourite;
        });
      })
      .then(() => system_request.get_solutions());
  } else {
    system_request.reset().then(() => {
      system_request.get_solutions();
    });
  }
});

function aredisjoint(set1, set2) {
  for (let i = 0; i < set1.length; i++) {
    for (let j = 0; j < set2.length; j++) {
      if (set1[i] == set2[j]) return false;
    }
  }
  return true;
}

function checkVisibility(element) {
  if (element.includeIf == null) {
    return true;
  }

  for (let c in element.includeIf) {
    let condition = element.includeIf[c];
    let selection = request.value[condition.field];
    if (selection == null) {
      return false;
    }

    if (Array.isArray(selection)) {
      if (aredisjoint(condition.values, selection)) {
        return false;
      }
    } else {
      if (!condition.values.includes(selection)) {
        return false;
      }
    }
  }
  return true;
}
</script>

<style scoped>
html,
body {
  overflow: hidden !important;
}
#application {
  display: flex !important;
  flex-direction: column;
}
#applicationCardText {
  flex-grow: 1;
  overflow-y: auto;
}
</style>
