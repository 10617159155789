<template>
  <v-row class="ma-0">
    <v-btn
      v-if="external_hook_source != null"
      class="send_to_external_app_btn"
      prepend-icon="mdi-share-circle"
      color="primary"
      @click="send_definition"
      >{{
        $t("home__data_exchange_with_app", {
          application_name: external_hook_source.data.app_name,
        })
      }}</v-btn
    >

    <v-col cols="12" xl="3" lg="4" md="5" sm="12">
      <ApplicationForm :projectID="projectID" :params="params" />
    </v-col>

    <v-col cols="12" xl="9" lg="8" md="7" sm="12">
      <v-card v-if="!settings.show_3d">
        <SolutionTable :show="!selected" @open_details="open_details" />
        <SolutionPanel
          v-if="selected"
          :system="selected"
          @unselected="selected = false"
        />
      </v-card>

      <v-card v-if="settings.show_3d">
        <SolutionAnimationRenderer />
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

import { useAPI } from "@/composables/useAPI";
import ApplicationForm from "@/components/ApplicationForm.vue";
import SolutionTable from "@/components/SolutionTable.vue";
import SolutionAnimationRenderer from "@/components/SolutionAnimationRenderer.vue";
import SolutionPanel from "@/components/SolutionPanel.vue";
import { useSystemRequestStore } from "@/stores/system_request";
import { useSettingsStore } from "@/stores/settings";

const settings = useSettingsStore();
const props = defineProps(["projectID", "params"]);
const system_request = useSystemRequestStore();
const { selected } = storeToRefs(system_request);

const external_hook_source = ref(null);

onMounted(() => {
  if (opener) {
    window.opener.postMessage({ protocol: "ready" }, "*");
    window.addEventListener("message", (event) => {
      if (
        event.data &&
        event.data.hasOwnProperty("app_identifier") &&
        event.data.app_identifier == "value-map"
      ) {
        external_hook_source.value = event;
      }
    });
  }
});

function send_definition() {
  external_hook_source.value.source.postMessage(
    { protocol: "result", definition: system_request.get_request_state() },
    external_hook_source.value.origin
  );
  window.close();
}

function open_details(item) {
  selected.value = item;
  window.scrollTo(0, 0);
}
</script>

<style>
.send_to_external_app_btn {
  position: fixed;
  bottom: 60px;
  right: 5px;
  z-index: 10000;
}
</style>
