<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col class="ml-1 mt-1">
            {{ $t("system_Management__title") }}
          </v-col>
          <v-spacer></v-spacer>
          <v-col>
            <v-tabs v-model="tab">
              <v-tab v-for="st in system_types">{{ st.name }}</v-tab>
            </v-tabs>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-card-title>

      <v-data-table-server
        id="scrollable_content"
        :search="searchTerm"
        :headers="header"
        :items="items"
        :items-length="totalItems"
        :items-per-page="10"
        @update:options="update_options($event)"
      >
        <template v-slot:item.name="{ item }">
          {{
            item.name == "Identification" ||
            item.name == "Identification and Dimensioning"
              ? item.system_name
              : item.name
          }}
        </template>

        <template v-slot:item.standard="{ item }">
          <v-checkbox disabled v-model="item.standard"> </v-checkbox>
        </template>

        <template v-slot:item.customer_specific="{ item }">
          <v-checkbox disabled v-model="item.customer_specific"> </v-checkbox>
        </template>

        <template v-slot:item.groups="{ item }">
          <v-select
            chips
            variant="underlined"
            multiple
            :items="all_groups"
            v-model="item.groups"
            @update:model-value="updatePermissions(item.id, item.groups)"
          >
            <template v-slot:chip="{ item }">
              <v-chip color="primary">{{ item.title }}</v-chip>
            </template>
          </v-select>
        </template>
      </v-data-table-server>
    </v-card>
  </v-container>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { useAPI } from "@/composables/useAPI";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const api = useAPI();

function update_options(e) {
  if (e.itemsPerPage < 0) {
    e.itemsPerPage = totalItems.value;
  }
  options.value = e;
}

const header = [
  { title: t("system_management__name"), key: "name", sortable: false },
  {
    title: t("system_management__type_code"),
    key: "type_code",
    sortable: false,
  },
  {
    title: t("system_management__material_number"),
    key: "material_number",
    sortable: false,
  },
  { title: t("system_management__note"), key: "note", sortable: false },
  {
    title: t("system_management__group"),
    key: "groups",
    sortable: false,
    width: "200px",
  },
  { title: t("system_management__standard"), key: "standard", sortable: false },
  {
    title: t("system_management__customer_specific"),
    key: "customer_specific",
    sortable: false,
  },
];

const system_types = [
  {
    name: "Dimensioning",
    endpoint: "trackntraceDimensioningList",
    permission_endpoint: "trackntraceDimensioningSetPermissions",
  },
  {
    name: "Identification",
    endpoint: "trackntraceIdentificationList",
    permission_endpoint: "trackntraceIdentificationSetPermissions",
  },
  {
    name: "OPS",
    endpoint: "trackntraceOpsList",
    permission_endpoint: "trackntraceOpsSetPermissions",
  },
  {
    name: "ICR",
    endpoint: "trackntraceIcrList",
    permission_endpoint: "trackntraceIcrSetPermissions",
  },
  {
    name: "Lector",
    endpoint: "trackntraceLectorList",
    permission_endpoint: "trackntraceLectorSetPermissions",
  },
  {
    name: "DWS",
    endpoint: "trackntraceDwsList",
    permission_endpoint: "trackntraceDwsSetPermissions",
  },
  {
    name: "VMS",
    endpoint: "trackntraceVmsList",
    permission_endpoint: "trackntraceVmsSetPermissions",
  },
  {
    name: "Scale",
    endpoint: "trackntraceScaleList",
    permission_endpoint: "trackntraceScaleSetPermissions",
  },
  {
    name: "RFID",
    endpoint: "trackntraceRfidList",
    permission_endpoint: "trackntraceRfidSetPermissions",
  },
];

const tab = ref(0);
var totalItems = ref(0);
var items = ref([]);
var options = ref({});
var searchTerm = ref("");
var all_groups = ref([]);

watch(options, (_) => {
  getDataFromApi();
});
watch(tab, (_) => {
  getDataFromApi();
});

function updatePermissions(id, permissions) {
  api.trackntraceAPI[system_types[tab.value].permission_endpoint](id, {
    group_name: permissions,
  });
}

function getDataFromApi() {
  const { _, page, itemsPerPage, search } = options.value;
  api.trackntraceAPI[system_types[tab.value].endpoint](page, itemsPerPage)
    .then((res) => {
      items.value = res.data.results;
      totalItems.value = res.data.count;
    })
    .catch((err) => {
      console.error(err);
    });
}

function getAllGroups() {
  const search_term = undefined;
  api.groupAPI.groupList(search_term, 1, -1).then((res) => {
    all_groups.value = res.data.results.map((x) => x.name);
  });
}

onMounted(() => {
  getAllGroups();
});
</script>

<style scoped>
#scrollable_content {
  flex-grow: 1;
  overflow-y: auto;
  max-height: calc(100vh - 180px);
}
</style>
