<template>
  <v-app-bar
    :color="settings.dark_mode ? '#252525' : '#404040'"
    extension-height="10"
    density="compact"
    elevation="1"
  >
    <v-app-bar-nav-icon @click="$emit('toggleMenu')"></v-app-bar-nav-icon>
    <v-btn
      elevation="0"
      @click="
        system_request.reset().then(() => system_request.get_solutions());
        router.push('/');
      "
      ><img src="/sick.png" width="60px"
    /></v-btn>
    <v-app-bar-title>{{ t("systemselektor") }} </v-app-bar-title>
    <v-spacer> </v-spacer>
    <v-btn
      @click="settings.toggle3d()"
      icon
    >
    <!-- ="settings.show_3d ? 'mdi-video-3d' : 'mdi-video-3d-off'" -->
    <v-icon v-if="settings.show_3d">mdi-video-3d</v-icon>
    <v-icon v-else>mdi-video-3d-off</v-icon>
    <v-tooltip activator="parent" location="bottom">
        {{ $t("appbar_toggle_3dview") }}
      </v-tooltip>
    </v-btn>

    <v-btn icon @click="settings.toggleDarkMode()">
      <v-icon v-if="settings.getDarkMode === 'light'">mdi-weather-sunny</v-icon>
      <v-icon v-else>mdi-weather-night</v-icon>
      <v-tooltip activator="parent" location="bottom">
        {{ $t("appbar_toggle_darkmode") }}
      </v-tooltip>
    </v-btn>

    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" icon>
          <v-icon>mdi-earth</v-icon>
          <v-tooltip activator="parent" location="bottom">
            {{ $t("appbar_language_options") }}
          </v-tooltip>
        </v-btn>
      </template>

      <v-list class="py-0">
        <v-list-item
          :active="settings.locale == l"
          v-for="l in $i18n.availableLocales"
          @click="settings.locale = l"
        >
          {{ l == "en" ? "English" : "German" }}
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- <v-btn class="text-none" stacked disabled>
      <v-badge :content="0" color="error">
        <v-icon>mdi-bell</v-icon>
      </v-badge>
      <TheNewsHub />
    </v-btn> -->

    <template v-slot:extension>
      <v-progress-linear
        v-if="show_progress_bar"
        color="primary"
        indeterminate
      ></v-progress-linear>
    </template>
  </v-app-bar>
</template>

<script setup>
import { computed, ref, watch, onMounted } from "vue";
import { useProgressBar } from "@/composables/useProgressBar";
import { useSettingsStore } from "@/stores/settings";
import router from "@/router";
import TheNewsHub from "@/components/TheNewsHub.vue";
import { useI18n } from "vue-i18n";
import { useSystemRequestStore } from "@/stores/system_request";

const system_request = useSystemRequestStore();
const { t, locale } = useI18n();
const props = defineProps(["app_name"]);
const settings = useSettingsStore();
const show_progress_bar = useProgressBar().active;

watch(
  () => settings.locale,
  () => {
    locale.value = settings.locale;
  }
);

onMounted(() => {
  locale.value = settings.locale;
});
</script>
