<template>
  <v-container>
    <v-card :title="$t('usermanagement__title')">
      <v-data-table-server id = "scrollable_content_and_footer"
        :search="searchTerm"
        :headers="header"
        :items="items"
        :items-length="totalItems"
        :items-per-page="20"
        @update:options="update_options($event)"
      >
        <template v-slot:top>
          <v-text-field
            class="mx-2"
            variant="underlined"
            prepend-inner-icon="mdi-magnify"
            :label="$t('search')"
            v-model="searchTerm"
          ></v-text-field>
        </template>
        <template v-slot:item.groups="{ item }">
          <v-chip-group
            column
            multiple
            v-model="item.groups"
            @update:model-value="manageUserGroup(item.id, item.groups)"
          >
            <v-chip
              :value="group"
              filter
              color="success"
              v-for="group in all_groups"
              >{{ group }}</v-chip
            >
          </v-chip-group>
        </template>
      </v-data-table-server>
    </v-card>
  </v-container>
</template>

<script setup>
import { useAPI } from "@/composables/useAPI";
import { ref, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const api = useAPI();

const header = [
  { title: t("usermanagement__username"), key: "username", sortable: false },
  { title: t("usermanagement__group"), key: "groups", sortable: false },
];

const all_groups = ref([]);
var totalItems = ref(0);
var items = ref([]);
var options = ref({});
var searchTerm = ref("");

function update_options(e) {
  if (e.itemsPerPage < 0) {
    e.itemsPerPage = totalItems.value;
  }
  options.value = e;
}

watch(options, (_) => {
  getDataFromApi();
});

function manageUserGroup(item_id, groups) {
  var permission_set = {};
  all_groups.value.forEach((group) => {
    permission_set[group] = groups.includes(group);
  });
  api.userAPI.userPermissions(item_id, permission_set)
}

function getDataFromApi() {
  const { _, page, itemsPerPage, search } = options.value;
  api.userAPI
    .userList(search, page, itemsPerPage)
    .then((res) => {
      items.value = res.data.results;
      totalItems.value = res.data.count;
    })
    .catch((err) => {
      console.error(err);
    });
}

function getAllGroups() {
  const search_term = undefined;
  api.groupAPI.groupList(search_term, 1, -1).then((res) => {
    all_groups.value = res.data.results.map((x) => x.name);
  });
}

onMounted(() => {
  getAllGroups();
});
</script>

<style scoped>
#scrollable_content_and_footer {
  flex-grow: 1;
  overflow-y: auto;
  height: 100vh;
  max-height: calc(100vh - 175px)
}
</style>
