import { defineStore } from "pinia";
import { ref, computed, watchEffect, watch } from "vue";
import { useAPI } from "@/composables/useAPI";
import {
    use_notification_center,
    NotificationType,
} from "@/stores/notification_center";
import { useI18n } from "vue-i18n";
import { useClipboard } from "@vueuse/core";
import { sectionMarginDefaults } from "docx";
import { useApiSchemaAdapter } from "@/composables/useApiSchemaAdapter";
import { useSettingsStore } from "@/stores/settings";


function useFormatter() {

    function getDropdownDefault(question) {
        let defaultOptions = question.options.filter(o => o.default === true)
        if (defaultOptions.length > 0) {
            return defaultOptions[0].label;
        }
        return null;
    }

    function getMultiSelectDefault(question) {
        return question.options
            .filter(option => option.default)
            .map(option => option.label);
    }

    function getSliderDefault(question) {
        return parseFloat(question.default);
    }

    function getRangeSliderDefault(question) {
        return [parseFloat(question.default_min), parseFloat(question.default_max)];
    }

    // Returns the default value for a given question based on its type.
    function getDefaultValue(question) {
        switch (question.type) {
            case "dropdown":
                return getDropdownDefault(question);
            case "multi-select":
                return getMultiSelectDefault(question);
            case "slider":
                return getSliderDefault(question);
            case "range-slider":
                return getRangeSliderDefault(question);
            default:
                return null;
        }
    }

    function toRequest(survey) {
        const request = {};
        for (const section of survey.sections) {
            for (const question of section.questions) {
                if (question.data_field) {
                    request[question.data_field] = getDefaultValue(question);
                } else {
                    request[section.label + '__' + question.label] = getDefaultValue(question);
                }
            }
        }
        return request;
    }

    return { toRequest };
}

export const useSystemRequestStore = defineStore("system_request", () => {
    const api = useAPI();
    const { t } = useI18n();
    const formatter = useFormatter();
    const request = ref({});
    const survey = ref({});
    const solutions = ref([]);
    const selected = ref(null);
    const sections = computed(() => survey.value.sections);
    const project = ref(null);
    const notification_center = use_notification_center();
    const { text, copy, copied, isSupported } = useClipboard();
    const { mapIfOld } = useApiSchemaAdapter();
    const settings = useSettingsStore();

    const project_active = computed(() => {
        return project.value != null;
    });

    function get_solutions() {
        // console.log(request.value)
        if (
            request.value == undefined ||
            Object.entries(request.value).length == 0
        ) {
            return;
        }
        return api.trackntraceAPI
            .trackntraceSolutionCreate(request.value)
            .then((res) => {
                solutions.value = res.data.solutions;
            });
    }

    function reset() {
        return api.request.get("survey/" + settings.activeSurvey).then((res) => {
            survey.value = res.data;
            res.data.sections.forEach((e) => {
                e.questions.sort((a, b) => (a.order > b.order ? 1 : -1));
            });
            project.value = null;
            selected.value = null;
            request.value = formatter.toRequest(res.data);
        });
    }

    function get_project(id) {
        return api.projectAPI
            .projectRead(id)
            .then((res) => {
                project.value = { ...res.data, definition: mapIfOld(res.data.definition) };
                request.value = project.value.definition;
            })
            .catch((err) => {
                notification_center.push_notification(
                    t("systems_request__failed_to_get_project"),
                    err,
                    NotificationType.Error
                );
            });
    }

    function set_project(id) {
        return reset()
            .then(() => id)
            .then(get_project)
            .then(get_solutions)
            .catch((err) => {
                console.log(err);
            });
    }

    function update_project() {
        return api.projectAPI
            .projectPartialUpdate(project.value.id, project.value)
            .then((res) => {
                notification_center.push_notification(
                    t("systems_request__save_project"),
                    t("systems_request__save_project_detail"),
                    NotificationType.Success
                );
            })
            .catch((err) => {
                notification_center.push_notification(
                    t("systems_request__failed_to_save_project"),
                    err,
                    NotificationType.Error
                );
            });
    }

    function update_favourite() {
        return api.projectAPI
            .projectPartialUpdate(project.value.id, {
                favourite: project.value.favourite,
                name: project.value.name ? project.value.name : "",
                customer: project.value.customer ? project.value.customer : "",
            })
            .then((res) => {
                notification_center.push_notification(
                    t("systems_request__save_project"),
                    t("systems_request__save_project_detail"),
                    NotificationType.Success
                );
            })
            .catch((err) => {
                notification_center.push_notification(
                    t("systems_request__failed_to_save_project"),
                    err,
                    NotificationType.Error
                );
            });
    }

    function get_request_state() {
        const params = encodeURI(JSON.stringify(request.value));
        return window.location.origin + "/#/systemselektor/request/" + params;
    }

    function share_project(explicit) {
        const params = encodeURI(JSON.stringify(request.value));
        var url = window.location.origin + "/#/systemselektor/request/" + params;
        var gUrl = null;
        if (explicit) {
            copy(url).then(() => {
                notification_center.push_notification(
                    t("systems_request__copy_url_project"),
                    url,
                    NotificationType.Success
                );
            });
        } else {
            api.shortenApi
                .shortenCreate({ link: url })
                .then((res) => {
                    return window.location.host + "/api/s/" + res.data + "/";
                })
                .then((url) => {
                    gUrl = url;
                    return copy(url);
                })
                .then(() => {
                    notification_center.push_notification(
                        t("systems_request__copy_url_project"),
                        gUrl,
                        NotificationType.Success
                    );
                });
        }
    }

    function copy_dialog(project_prompt, definition) {
        const definition_copy = JSON.parse(JSON.stringify(definition));

        const new_project = {
            name: "",
            description: "",
            customer: "",
            definition: definition_copy,
        };

        return project_prompt.value
            .open(new_project)
            .then((p) => {
                return api.projectAPI.projectCreate(p);
            })
            .then((res) => {
                notification_center.push_notification(
                    t("systems__project_copied"),
                    "",
                    NotificationType.Success
                );

                return res.data;
            })
            .catch((err) => {
                if (err) {
                    notification_center.push_notification(
                        t("systems__project_copy_failed"),
                        err,
                        NotificationType.Error
                    );
                }
            });
    }

    function edit_dialog(project_prompt, project) {
        const item_copy = JSON.parse(JSON.stringify(project));
        return project_prompt.value
            .open(item_copy)
            .then((p) => {
                return api.projectAPI.projectPartialUpdate(p.id, {
                    name: p.name,
                    description: p.description,
                    customer: p.customer,
                });
            })
            .then((res) => {
                notification_center.push_notification(
                    t("systems__project_updated"),
                    "",
                    NotificationType.Success
                );

                return res.data;
            })
            .catch((err) => {
                if (err) {
                    notification_center.push_notification(
                        t("systems__project_edit_failed"),
                        err,
                        NotificationType.Error
                    );
                }
            });
    }

    function create_dialog(project_prompt, request) {
        const new_project = {
            name: "",
            description: "",
            customer: "",
            definition: request,
        };

        return project_prompt.value
            .open(new_project)
            .then((p) => {
                return api.projectAPI.projectCreate(p);
            })
            .then((res) => {
                notification_center.push_notification(
                    t("systems__project_saved"),
                    "",
                    NotificationType.Success
                );

                return res.data;
            })
            .catch((err) => {
                if (err) {
                    notification_center.push_notification(
                        t("systems__project_create_failed"),
                        err,
                        NotificationType.Error
                    );
                }
            });
    }

    return {
        reset,
        sections,
        request,
        survey,
        solutions,
        set_project,
        project_active,
        share_project,
        project,
        update_project,
        selected,
        update_favourite,
        copy_dialog,
        edit_dialog,
        create_dialog,
        get_solutions,
        get_request_state
    };
});
