<template>
  <div>
    <v-list lines="two" :items="system_view" item-props>
      <template v-slot:item="{ props }">
        <v-list-item
          :title="props.title"
          :subtitle="props.subtitle"
          :show="props.show"
          :value="props"
        >
          <template v-slot:title>
            <span :class="props.color ? 'bg-' + props.color : 'default'">
              {{ props.title }}
            </span>
          </template>
          
          <template v-slot:prepend>
            <v-icon>{{ props.prependIcon }}</v-icon>
          </template>
        </v-list-item>
      </template>
    </v-list>

    <v-list v-if="notes.length > 0">
      <v-list-subheader>{{ $t("system_detail__notes") }}</v-list-subheader>
      <v-list-item prepend-icon="mdi-message-bulleted" v-for="note in notes">{{
        note
      }}</v-list-item>
    </v-list>

    <!-- <v-list>
      <v-list-subheader>{{ $t("system_detail__remarks") }}</v-list-subheader>
      <div class="d-flex flex-wrap justify-begin">
        <v-chip
          v-if="system.guide.length == 0"
          color="success"
          variant="flat"
          class="ma-4"
          >{{ $t("system_detail__no_restrictions") }}</v-chip
        >
        <v-chip
          v-else
          v-for="g in system.guide"
          variant="flat"
          class="ma-2"
          :color="g.type"
          >{{ g.description }}</v-chip
        >
      </div>
    </v-list> -->

    <v-list>
      <v-list-subheader>{{ $t("system_detail__disclaimer") }}</v-list-subheader>

      <div class="px-2 text-grey">
        <v-list-item>
          {{ $t("system_detail__disclaimer_text") }}
        </v-list-item>
      </div>
    </v-list>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { useI18n } from "vue-i18n";
import { useSettingsStore } from "@/stores/settings";

const { height, width } = useDisplay();
const props = defineProps(["system"]);
const { t, locale } = useI18n();
const settings = useSettingsStore();

const system_view = computed(() => {
  const system = JSON.parse(JSON.stringify(props.system));

  var view = [
    { type: "subheader", title: t("system_detail__general"), show: true },
    {
      title: t("system_detail__name"),
      subtitle: () => (system.system_name ? system.system_name : system.name),
      prependIcon: "mdi-text-box",
      show: true,
    },
    {
      title: t("system_detail__notes"),
      subtitle: () => system.notes,
      prependIcon: "mdi-note-text",
      show: true,
    },
    {
      title: t("system_detail__price"),
      subtitle: () => system.costs.toLocaleString() + " €",
      prependIcon: "mdi-cash",
      show: settings.show_system_price,
    },
    {
      title: t("system_detail__reading"),
      subtitle: () => system.system_type,
      prependIcon: "mdi-apps",
      show: system.system_type,
    },
    {
      title: t("system_detail__reading"),
      subtitle: () => system.reading_sides.join(", "),
      prependIcon: "mdi-apps",
      show: system.reading_sides,
    },
    {
      title: t("system_detail__typecode"),
      subtitle: () => system.type_code,
      prependIcon: "mdi-numeric",
      show: system.type_code,
    },
    {
      title: t("system_detail__materialnumber"),
      subtitle: () => system.material_number,
      prependIcon: "mdi-identifier",
      show: system.material_number,
    },
    {
      title: t("system_detail__typ"),
      subtitle: () =>
        system.lector_system_type ? system.lector_system_type[0] : system.type,
      prependIcon: "mdi-label",
      show: system.type,
    },
    {
      title: t("system_detail__frame"),
      subtitle: () =>
        t("system_detail__frame_pn") +
        ": " +
        (system.frame_pn ? system.frame_pn : "N/A"),
      prependIcon: "mdi-artboard",
      show: system.frame_pn,
      color: "red",
    },
  ];

  var detail_view = [];
  if (
    system.scanner_count ||
    system.clv_type ||
    system.ops_type ||
    system.controller_type ||
    system.focus_type ||
    system.reading_count ||
    system.scale_type
  ) {
    detail_view = [
      {
        type: "subheader",
        title: t("system_detail__system_details"),
        show: true,
      },
      {
        title: t("system_detail__scanner"),
        subtitle: () => {
          return system.reading_count
            ? system.reading_count
            : system.clv_count + " x " + system.clv_type;
        },
        prependIcon: "mdi-barcode",
        show: system.type.includes("ICR"),
      },
      {
        title: t("system_detail__icr"),
        subtitle: () => {
          return (
            (system.reading_count ? system.reading_count : system.icr_count) +
            " x " +
            (system.camera_type ? system.camera_type : system.icr_type)
          );
        },
        prependIcon: "mdi-camera",
        show: system.type.includes("ICR"),
      },
      {
        title: t("system_detail__lector"),
        subtitle: () => {
          return (
            (system.reading_count
              ? system.reading_count
              : system.lector_count) +
            " x " +
            system.lector_type
          );
        },
        prependIcon: "mdi-camera",
        show: system.type.includes("Lector"),
      },
      {
        title: t("system_detail__heads"),
        subtitle: () => system.reading_count + " x " + system.vms_head,
        prependIcon: "mdi-camera",
        show: system.type.includes("VMS"),
      },
      {
        title: t("system_detail__supported_weight"),
        subtitle: () =>
          system.min_object_weight + "g - " + system.max_object_weight + "kg",
        prependIcon: "mdi-weight",
        show: system.min_object_weight != null && system.max_object_weight,
      },
      {
        title: t("system_detail__calibration"),
        subtitle: () => system.calibration_value + "g",
        prependIcon: "mdi-scale",
        show: system.min_object_weight != null && system.max_object_weight,
      },
      {
        title: t("system_detail__lft"),
        subtitle: () =>
          system.lft ? t("system_detail__yes") : t("system_detail__no"),
        prependIcon: "mdi-scale-balance",
        show: system.lft != null,
      },
      {
        title: t("system_detail__max_pitch"),
        subtitle: () => system.max_object_pitch + " mm",
        prependIcon: "mdi-screen-rotation",
        show: system.max_object_pitch != null,
      },
      {
        title: t("system_detail__max_packet_size"),
        subtitle: () =>
          system.max_object_length + "mm x " + system.max_object_width + "mm",
        prependIcon: "mdi-move-resize",
        show: system.max_object_length != null && system.max_object_width,
      },
      {
        title: t("system_detail__additional_conveyor"),
        subtitle: () => system.additional_conveyors,
        prependIcon: "mdi-arrow-right-bold-box",
        show: system.additional_conveyors,
      },
      {
        title: t("system_detail__max_throughput"),
        subtitle: () => system.throughput + " p/h",
        prependIcon: "mdi-speedometer",
        show: system.throughput,
      },
      {
        title: t("system_detail__speed"),
        subtitle: () =>
          Number(system.speed.min).toFixed(2) +
          " - " +
          Number(system.speed.max).toFixed(2) +
          " m/s",
        prependIcon: "mdi-speedometer",
        show: system.speed,
      },
      {
        title: t("system_detail__packet_size"),
        subtitle: () =>
          system.sizes.l.min +
          " - " +
          system.sizes.l.max +
          " mm; " +
          system.sizes.b.min +
          " - " +
          system.sizes.b.max +
          " mm; " +
          system.sizes.h.min +
          " - " +
          system.sizes.h.max +
          " mm",
        prependIcon: "mdi-move-resize",
        show: system.speed,
      },
      {
        title: t("system_detail__focus"),
        subtitle: () => system.focus_type,
        prependIcon: "mdi-image-filter-center-focus",
        show: system.focus_type,
      },
      {
        title: t("system_detail__antenna"),
        subtitle: () => system.antenna_count,
        prependIcon: "mdi-antenna",
        show: system.antenna_count,
      },
      {
        title: t("system_detail__rfid"),
        subtitle: () => system.rfid_device,
        prependIcon: "mdi-chip",
        show: system.rfid_device,
      },
      {
        title: t("system_detail__controller"),
        subtitle: () => system.controller_type,
        prependIcon: "mdi-memory",
        show: system.controller_type,
      },
    ];
  }

  var additional_informations_view = [];
  if (
    (system.measurements.l && system.measurements.h && system.measurements.w) ||
    system.maximal_object_height ||
    system.max_conveyor_width ||
    system.measurements.weight
  ) {
    additional_informations_view = [
      {
        type: "subheader",
        title: t("system_detail__additional_information"),
        show: true,
      },
      {
        title: t("system_detail__size"),
        subtitle: () => system_measurements.value,
        prependIcon: "mdi-tape-measure",
        show:
          system.measurements.l &&
          system.measurements.h &&
          system.measurements.w,
      },
      {
        title: t("system_detail__weight"),
        subtitle: () => system.measurements.weight + " kg",
        prependIcon: "mdi-weight",
        show: system.measurements.weight,
      },
      {
        title: t("system_detail__max_covneyor_width"),
        subtitle: () => system.max_conveyor_width + " mm",
        prependIcon: "mdi-ticket-confirmation",
        show: system.max_conveyor_width,
      },
      {
        title: t("system_detail__max_object_height"),
        subtitle: () => system.maximal_object_height + "mm",
        prependIcon: "mdi-package-variant-closed",
        show: system.maximal_object_height,
      },
    ];
  }

  view = [...view, ...detail_view, ...additional_informations_view];
  view
    .filter((x) => x.show)
    .filter((x) => Object.hasOwn(x, "subtitle"))
    .map((x) => (x.subtitle = x.subtitle()));
  return view.filter((x) => x.show);
});

const system_measurements = computed(() => {
  return (
    props.system.measurements.l +
    "mm x " +
    props.system.measurements.w +
    "mm x " +
    props.system.measurements.h +
    "mm"
  );
});

const notes = computed(() => {
  var notes = [];
  if (props.system.note != null && props.system.note != "") {
    notes = props.system.note.split(/\r?\n/);
  }
  return notes.concat(
    props.system.guide.map((x) =>
      locale.value == "en" ? x.description_en : x.description
    )
  );
});

const systemType = computed(() => {
  if (props.system.type == "OPS") {
    return "OPS (" + props.system.ops_type + ")";
  } else if (props.system.type == "ICR") {
    return "ICR" + (props.system.crossbeam ? " (Crossbeam)" : "");
  } else if (props.system.type == "Lector") {
    return "Lector (" + props.system.lector_system_type + ")";
  } else if (props.system.type == "RFID") {
    return "RFID (" + props.system.rf_system_type + ")";
  } else if (props.system.type == "VMS") {
    return "VMS (" + props.system.vms_type + ")";
  } else if (props.system.type == "Scale") {
    return "Scale (" + props.system.scale_type + ")";
  } else if (props.system.type == "DWS") {
    return "DWS (" + props.system.dws_type + ")";
  }
});
</script>
