<template>
  <v-data-table-virtual
    class="py-3"
    :headers="headers"
    :items="items"
    v-model="selected"
    show-select
    item-value="label"
  >
  </v-data-table-virtual>

  <v-card-actions>
    <v-btn
      color="primary"
      elevation="3"
      ripple
      variant="tonal"
      @click="handleDownload(selected)"
    >
      Download {{ selected.length }} file{{ selected.length == 1 ? "" : "s" }}
    </v-btn>
  </v-card-actions>
</template>

<script setup>
import { ref, onMounted, computed, capitalize } from "vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { useI18n } from "vue-i18n";
import useReportGenerator from "@/composables/useReportGenerator";
import { useS3 } from "@/composables/useS3";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  use_notification_center,
  NotificationType,
} from "@/stores/notification_center";

const { height, width } = useDisplay();
const props = defineProps(["system"]);
const { t, te } = useI18n();
const { downloadPDFReport, yyyymmdd, downloadWordReport } = useReportGenerator(t, te);
const s3 = useS3();
const notification_center = use_notification_center();

const selected = ref([]);

const headers = ref([
  {
    title: "Type",
    align: "middle",
    key: "label",
  },
  {
    title: "Filename",
    align: "start",
    key: "filename",
  },
  // {
  //   title: "Size",
  //   align: "middle",
  //   key: "size",
  // },
]);

const items = computed(() => {
  if (!props.system) return [];
  const files = [
    { id: "step_file", label: "CAD (.STEP)" },
    { id: "bom_file", label: "Bill of Materials" },
    { id: "eDraw_easm", label: "eDrawing (.easm)" },
    { id: "wiring", label: "Wiring Diagram" },
    { id: "drawing", label: "Technical Drawing" },
    { id: "frame_documents", label: "Frame Documents" },
  ];
  const res = [];
  for (let i = 0; i < files.length; i++) {
    let fileUrl = props.system[files[i].id];
    if (fileUrl != null) {
      res.push({
        label: files[i].label,
        url: fileUrl,
        filename: getFileName(fileUrl),
        size: "n.a.",
      });
    }
  }
  res.push(
    {
      label: "PDF Report",
      url: "",
      filename:
        "Systemselektor_Report_" +
        props.system.id +
        "_" +
        yyyymmdd(new Date()) +
        ".pdf",
      size: "n.a.",
    },
    {
      label: "Word Report",
      url: "",
      filename:
        "Systemselektor_Report_" +
        props.system.id +
        "_" +
        yyyymmdd(new Date()) +
        ".docx",
      size: "n.a.",
    }
  );
  return res;
});

function handleDownload(selected) {
  if (selected.length == 0) {
    notification_center.push_notification(
      t("Please select at least one file"),
      t(""),
      NotificationType.Warning
    );
  } else if (selected.length == 1) {
    downloadSingleFile(selected);
  } else {
    downloadZip(selected);
  }
}

function downloadZip(selected) {
  const zip = new JSZip();
  const selectedItems = items.value.filter((item) =>
    selected.includes(item.label)
  );
  for (const data of selectedItems) {
    if (data.label == "PDF Report") {
      const pdfReport = downloadPDFReport(
        s3.get(s3.Bucket.PUBLIC, ""),
        true,
        data.filename
      );
      zip.file(data.filename, pdfReport);
    } else if (data.label == "Word Report") {
      const wordReport = downloadWordReport(
        s3.get(s3.Bucket.PUBLIC, ""),
        true,
        data.filename
      );
      zip.file(data.filename, wordReport);
    } else {
      zip.file(data.filename, getBinaryOfFile(data.url));
    }
  }
  zip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(
      content,
      props.system.type + "_" + props.system.material_number + ".zip"
    );
  });
}

function downloadSingleFile(selected) {
  let item = items.value.find((item) => item.label == selected[0]);
  if (item.label == "PDF Report") {
    downloadPDFReport(s3.get(s3.Bucket.PUBLIC, ""), false, item.filename);
  } else if (item.label == "Word Report") {
    downloadWordReport(s3.get(s3.Bucket.PUBLIC, ""), false, item.filename);
  } else {
    downloadFile(item.url, item.filename);
  }
}

function downloadFile(url, name) {
  fetch(url).then((response) => {
    return response.blob().then((b) => {
      const a = document.createElement("a");
      a.setAttribute("download", name);
      a.href = URL.createObjectURL(b);
      a.click();
    });
  });
}

function getFileName(url) {
  const res = new URL(url).pathname.split("/");
  return res[res.length - 1];
}

async function getBinaryOfFile(url) {
  const data = await fetch(url, { method: "GET" });
  const buf = await data.arrayBuffer();
  return buf;
}
</script>
