<template>
  <template
    v-for="(question, i) in section.questions"
    :key="question.label"
    class="pl-3"
  >
    <div v-show="checkVisibility(question)">
      <v-divider v-if="i != 0" class="my-5"></v-divider>
      <v-label class="pb-5">
        {{
          $te("system_request_" + section.label + "_" + question.label)
            ? $t("system_request_" + section.label + "_" + question.label)
            : question[settings.locale]
        }}
      </v-label>

      <v-row dense align="center" justify="space-between">
        <v-col v-if="question.image" :cols="2">
          <v-img width="60" :src="question.image" color="white" />
        </v-col>
        <v-col :cols="calculateQuestionSpace(question)">
          <BaseBinaryInputField
            v-if="question.type === 'binary-input'"
            v-model="request[question.data_field]"
            :question="question"
            @switchClicked="requestStore.get_solutions()"
          >
          </BaseBinaryInputField>
          <BaseTextInputField
            v-else-if="question.type === 'text-input'"
            v-model="
              request[
                question.data_field
                  ? question.data_field
                  : section.label + '__' + question.label
              ]
            "
            :question="question"
            :section_label="section.label"
            @chip-clicked="requestStore.get_solutions()"
          />
          <BaseSlider
            v-else-if="question.type === 'slider'"
            v-model="
              request[
                question.data_field
                  ? question.data_field
                  : section.label + '__' + question.label
              ]
            "
            :question="question"
            :section_label="section.label"
            @slider-released="requestStore.get_solutions()"
          />
          <BaseSliderRanged
            v-else-if="question.type === 'range-slider'"
            v-model="
              request[
                question.data_field
                  ? question.data_field
                  : section.label + '__' + question.label
              ]
            "
            :question="question"
            :section_label="section.label"
            @slider-released="requestStore.get_solutions()"
          />
          <BaseSelect
            v-else
            v-model="
              request[
                question.data_field
                  ? question.data_field
                  : section.label + '__' + question.label
              ]
            "
            :question="question"
            :section_label="section.label"
            @chip-clicked="requestStore.get_solutions()"
          />
        </v-col>
        <v-col v-if="showInfo(question) !== ''" :cols="1">
          <v-tooltip
            location="bottom"
            max-width="250"
          >
            <template v-slot:activator="{ props }">
              <v-icon color="blue" size="default" v-bind="props" :style="{ cursor: 'pointer' }">
                mdi-information
              </v-icon>
            </template>
            <span>{{ showInfo(question) }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </div>
  </template>
</template>

<script setup>
import { ref, computed } from "vue";
import BaseSelect from "@/components/BaseSelect.vue";
import BaseSlider from "@/components/BaseSlider.vue";
import BaseSliderRanged from "@/components/BaseSliderRanged.vue";
import BaseTextInputField from "@/components/BaseTextInputField.vue";
import BaseBinaryInputField from "@/components/BaseBinaryInputField.vue";
import { storeToRefs } from "pinia";
import { useSystemRequestStore } from "@/stores/system_request";
import { useSettingsStore } from "@/stores/settings";

const settings = useSettingsStore();
const requestStore = useSystemRequestStore();
const { request } = storeToRefs(useSystemRequestStore());
const props = defineProps(["section"]);

function aredisjoint(set1, set2) {
  for (let i = 0; i < set1.length; i++) {
    for (let j = 0; j < set2.length; j++) {
      if (set1[i] == set2[j]) return false;
    }
  }
  return true;
}

function showInfo(question) {
  if (settings.locale === "en") {
    return question.help_text_en;
  } else if (settings.locale === "de") {
    return question.help_text_de;
  }
}

function calculateQuestionSpace(question) {
  let cols = 12;
  if(question.image) {
    cols -= 2;
  } 
  if(showInfo(question) !== "") {
    cols -= 1;
  }
  return cols;
}

function checkVisibility(element) {
  if (element.includeIf == null) {
    return true;
  }

  for (let c in element.includeIf) {
    let condition = element.includeIf[c];
    let selection = request.value[condition.field];
    if (selection == null) {
      return false;
    }

    if (Array.isArray(selection)) {
      if (aredisjoint(condition.values, selection)) {
        return false;
      }
    } else {
      if (!condition.values.includes(selection)) {
        return false;
      }
    }
  }
  return true;
}
</script>
