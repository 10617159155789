import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useSettingsStore = defineStore(
  "settings",
  () => {
    const locale = ref("en");
    const dark_mode = ref(false);
    const show_3d = ref(false);
    const show_system_price = ref(true);
    const show_system_price_in_report = ref(false);
    const activeSurvey = ref("default");
    // table options
    const group_solutions = ref(true);
    const selected_columns = ref([
      "type",
      "name",
      "max_conveyor_width",
      "maximal_object_height",
      "material_number",
      "costs",
      "has_tco_support",
      "guide_len",
    ]);
    const redirect_uri = ref("/");

    const getDarkMode = computed(() => {
      return dark_mode.value ? "dark" : "light";
    });

    function toggleDarkMode() {
      dark_mode.value = !dark_mode.value;
    }

    function toggle3d() {
      show_3d.value = !show_3d.value;
    }

    return {
      locale,
      dark_mode,
      show_3d,
      show_system_price,
      show_system_price_in_report,
      selected_columns,
      group_solutions,
      getDarkMode,
      redirect_uri,
      activeSurvey,
      toggleDarkMode,
      toggle3d,
    };
  },
  {
    persist: true, // set to true for production
  }
);
